<template>
    <div>

        <cHeaderBuyer activeNav = "atc"></cHeaderBuyer>
        <div class = "body">
              <div class = "page-wrap">
                    <cAtcMenu></cAtcMenu>
                     <div class = "col-right">
                         <div class="container">
                             <div class="nav-title">
                                 角色管理
                                 <div class="nav">
                                     <div class="search">
                                         <a-input-search v-model="queryParams.keyword" placeholder="请输入企业名称/简称" enter-button @search="onSearch" />
                                     </div>
                                     <div class="btns">
                                         <a-button @click="$router.push('/atc/attestation-add')">认证新企业</a-button>
                                     </div>
                                 </div>
                             </div>
                            <div class="hr"></div>
                             <div class="table-wrap">
                                 <a-table :columns="columns" :data-source="companyList">
                                <div slot="enterpriseName" slot-scope="text, record">
                                    <div class="enterprise-info">
                                        <div class="head-img">
                                            <img :src = "record.enterpriseLogo" alt = "">
                                        </div>
                                        <div class="ets-info-wrap"><div class="ets-info"><span>{{ record.enterpriseNameAbbreviation }}</span></div><p>{{ record.unifiedSocialCreditCode }} | {{ record.enterpriseName }}</p></div>
                                    </div>
                                </div>
                              </a-table>
                             </div>
                         </div>
                    </div>
              </div>
        </div>

    </div>
</template>

<script>

    import cHeaderBuyer from "@/component/header-buyer";
    import cAtcMenu from "@/component/atc-menu";
    import service from "../../../utils/request";
export default {
    name: "attestation",
    components: {
        cHeaderBuyer,
        cAtcMenu
    },
    data(){
        return {
            columns:[
                {
                    title:"企业",
                    dataIndex:"enterpriseName",
                    key:"enterpriseName",
                    scopedSlots: { customRender: 'enterpriseName' },
                }
            ],
            companyList:[],
            queryParams: {
                page: 1,
                pageSize: 20,
                keyword:'',
            }
        }
    },
    created() {
        this.getList();
    },
    methods:{
        onSearch(e){
            console.log(e)
            this.queryParams.page = 1;
            this.queryParams.keyword = e;
            this.getList();
        },
        getList(){
            console.log(this.queryParams)
            service.post(service.uri.company.getList, this.queryParams).then(res => {
                this.companyList = res.data.list;
                this.queryParams.page++;
            })
        }
    }
}
</script>


<style lang = "less" scoped>
.enterprise-info {
    display: flex;
    .head-img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        overflow: hidden;
        margin-right: 10px;
        img {
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
        }
    }
    .ets-info-wrap {
        padding: 0;
        line-height: 20px;
        height: 20px;
        .ets-info {
            height: 20px;
            display: flex;
            margin-bottom: 3px;
        }
        p {
            height: 17px;
            margin-bottom: 0;
        }
    }
}
.body {
    background: #fbfbfb;
}

.page-wrap {
    width: 1200px;
    margin: 0 auto;
}
a {
    color: #de0000;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: 0.5em;
    margin-left: 0;
}


.body {
    background: #fbfbfb;

    .page-wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }
}
.ant-table-tbody > tr > td {
    padding: 33px 16px;
    border-bottom: 1px solid #dedede;
}

.ant-table-row {
    padding: 32px 0;
}

.table-wrap {
    padding: 0 20px;
}

.hr {
    height: 20px;
    background: #fbfbfb;
}

.container {
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border: 0 solid #ededed;
    background: #fff;
    min-height: 580px;
    padding: 20px 0 0;

    .table-wrap {
        padding: 20px;
    }
    .nav-title {
        color: #333;
        font-size: 14px;
        font-weight: 500;
        line-height: 50px;
        padding: 0 20px;
    }
    .nav {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }
    .btns, .search {
        height: 35px;
        display: flex;
        align-items: center;
    }
}

img {
    display: block;
    width: 100%;
    height: 100%;
}

.info-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    padding: 0 20px;

    .head-info-wrap {
        width: 136px;
        height: 124px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;

        .head {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            overflow: hidden;
        }

        .name {
            color: #333;
            margin: 13px 5px 0;
            line-height: 20px;
            word-break: break-all;
            word-wrap: break-word;
            text-align: center;
        }
    }

    .user-info-wrap {
        width: 100%;
        padding: 20px;

        li {
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
}

</style>

