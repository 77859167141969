<template>
    <div class="col-left">
        <div class="left-menu"></div>
        <div class="left-menu">
            <dl v-for="(item, idx) in menus" :key="idx">
                <dt>
                    <template v-if="item.path">
                        <a :href="item.path" :class="item.path == path ? 'router-link-active' : ''" @click="changeTPath(idx)">{{item.name}}</a>
                    </template>
                    <template v-else>{{item.name}}</template>
                </dt>
                <dd v-for="(child, cIdx) in item.children" :key="cIdx">
                    <a :href="child.path"  :class="child.path == path ? 'router-link-active' : ''" @click="changePath(idx, cIdx)">{{child.name}}</a>
                </dd>
            </dl>
        </div>
    </div>
</template>

<script>
    export default {
        name: "atc-menu",
        data(){
            return {
                menus:[
                    {
                        name: "帐户管理",
                        children:[
                            {name:"我的帐户", path:"/#/atc/account"}
                        ]
                    },
                    {
                        name: "企业管理",
                        children:[
                            {name:"我的企业", path:"/#/atc/attestation"}
                        ]
                    }
                ]
            }
        },
        created(){
            this.path = '/#'+this.$route.path;
        },
        methods:{
            changeTPath(idx){
                this.path = this.menus[idx].path;
            },
            changePath(idx, cIdx){
                this.path = this.menus[idx].children[cIdx].path;
            }
        }
    }
</script>

<style lang="scss" scoped>

    dd {
        margin-bottom: 0.5em;
        margin-left: 0;
    }

    .col-left {
        width: 180px;
        background-color: #fff;
        min-height: 800px;

        .left-menu {
            padding: 10px 0 0 40px;
            font-family: PingFangSC-Medium, PingFang SC;

            a {
                color: #333;
            }

            dl {
                margin-bottom: 10px;

                dt {
                    line-height: 40px;
                    color: #333;
                    font-size: 16px;
                    font-weight: 700;
                }

                dd {
                    font-size: 12px;
                    line-height: 24px;
                    color: #333;
                }
            }
        }
    }
</style>